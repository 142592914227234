import React from 'react'
import Helmet from 'react-helmet'
// import { useStaticQuery, graphql } from 'gatsby'

const SEO = () => {
  // const { site } = useStaticQuery(query)

  // const {
  //   buildTime,
  //   siteMetadata: { siteUrl, author }
  // } = site
  
  // const homeURL = `${siteUrl}`
  // const ogImage = banner
  // const seo = {
  //   title: title || defaultTitle,
  //   description: desc || defaultDescription,
  //   image: `${siteUrl}${ogImage}`,
  //   url: `${siteUrl}${pathname || ''}`,
  // }
  
  // const schemaOrgWebPage = {
  //   '@context': 'http://schema.org',
  //   '@type': 'WebPage',
  //   url: `${homeURL}${pathname}`,
  //   headline: title,
  //   inLanguage: locale,
  //   mainEntityOfPage: `${homeURL}${pathname}`,
  //   description: desc || defaultDescription,
  //   name: defaultTitle,
  //   author: {
  //     '@type': 'Person',
  //     name: author,
  //   },
  //   copyrightHolder: {
  //     '@type': 'Person',
  //     name: author,
  //   },
  //   copyrightYear: '2021',
  //   creator: {
  //     '@type': 'Person',
  //     name: author,
  //   },
  //   publisher: {
  //     '@type': 'Person',
  //     name: author,
  //   },
  //   datePublished: '2021-04-20T10:30:00+01:00',
  //   dateModified: buildTime,
  //   image: {
  //     '@type': 'ImageObject',
  //     url: ogImage,
  //   },
  // }
  
  return (
    <Helmet title='AFC' defer={false}>
      <html lang='fr' />
      {/* <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="title" content={seo.title} />
      <link rel="canonical" href={seo.url}></link> */}
      {/* <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script> */}
    </Helmet>
  )
}

export default SEO;

// const query = graphql`
//   query SEO {
//     site {
//       buildTime(formatString: "YYYY-MM-DD")
//       siteMetadata {
//         siteUrl
//         author
//       }
//     }
//   }
// `